import { NumberInput } from '@mantine/core';
import React from 'react';
import { CustomNumberInputProps } from './CustomNumberInput.model';

export function CustomNumberInput(
  {
    label,
    name,
    inputProps,
    required,
    disabled,
    width,
    mr,
    rightSection,
  }: CustomNumberInputProps) {

  return (<NumberInput
    {...inputProps}
    name={name}
    label={label}
    withAsterisk={required}
    id={'print-input-field'}
    mt={10}
    mr={mr}
    radius={10}
    w={width}
    hideControls
    disabled={disabled}
    thousandSeparator={' '}
    decimalSeparator={','}
    decimalScale={3}
    rightSection={rightSection ?? 'Kč'}
  />);
}
